<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Remolques</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operacion</li>
                  <li class="breadcrumb-item active">Remolques</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-3 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Remolques</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Documentos"
                    data-toggle="tab"
                    href="#Documentos"
                    v-if="form.id != null"
                    >Documentos</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div :class="form.id ? 'col-md-9' : 'col-md-12'">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="empresa">Empresa</label>
                              <v-select
                                :class="[
                                  $v.form.empresa_id.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid',
                                  $store.getters.getDarkMode
                                    ? 'dark-vselect'
                                    : '',
                                ]"
                                :disabled="opcion == 1 || opcion == 3"
                                v-model="empresa"
                                placeholder="Empresa"
                                label="razon_social"
                                class="form-control form-control-sm p-0"
                                :options="listasForms.empresas"
                                @search="selectEmpresa"
                                @input="checkEmpresa()"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="placa">Placa</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="text"
                                class="form-control form-control-sm"
                                id="placa"
                                maxlength="6"
                                v-model="form.placa"
                                :class="
                                  $v.form.placa.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaRemolque()"
                              />
                              <div class="error" v-if="!$v.form.placa.required">
                                Diligencie una placa
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="tipo_carroceria"
                                >Tipo de Carrocería</label
                              >
                              <select
                                :disabled="opcion == 1 || opcion == 3"
                                class="form-control form-control-sm"
                                id="tipo_carroceria"
                                v-model="form.tipo_carroceria"
                                :class="
                                  $v.form.tipo_carroceria.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="tipo_carroceria in listasForms.tipos_carroceria"
                                  :key="tipo_carroceria.numeracion"
                                  :value="tipo_carroceria.numeracion"
                                >
                                  {{ tipo_carroceria.descripcion }}
                                </option>
                              </select>
                              <div
                                class="error"
                                v-if="!$v.form.tipo_carroceria.required"
                              >
                                Seleccione una tipo de carrocería
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="modelo">Modelo</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="number"
                                class="form-control form-control-sm"
                                id="modelo"
                                min="1970"
                                max="2100"
                                v-model="form.modelo"
                                :class="
                                  $v.form.modelo.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                              <div
                                class="error"
                                v-if="!$v.form.modelo.required"
                              >
                                Diligencie un modelo
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="numero_ejes">Número de ejes</label>
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="number"
                                class="form-control form-control-sm"
                                id="numero_ejes"
                                min="1"
                                max="20"
                                v-model="form.numero_ejes"
                                :class="
                                  $v.form.numero_ejes.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                              <div
                                class="error"
                                v-if="!$v.form.numero_ejes.required"
                              >
                                Diligencie un número de ejes
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label for="ancho"
                                >Ancho <sub>(metros)</sub></label
                              >
                              <input
                                :disabled="opcion == 1"
                                type="number"
                                class="form-control form-control-sm"
                                id="ancho"
                                min="1"
                                max="99"
                                v-model="form.ancho"
                                :class="
                                  $v.form.ancho.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                              <div class="error" v-if="!$v.form.ancho.required">
                                Diligencie un ancho
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="largo"
                                >Largo <sub>(metros)</sub></label
                              >
                              <input
                                :disabled="opcion == 1"
                                type="number"
                                class="form-control form-control-sm"
                                id="largo"
                                min="1"
                                max="99"
                                v-model="form.largo"
                                :class="
                                  $v.form.largo.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                              <div class="error" v-if="!$v.form.largo.required">
                                Diligencie un largo
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="alto">Alto <sub>(metros)</sub></label>
                              <input
                                :disabled="opcion == 1"
                                type="number"
                                class="form-control form-control-sm"
                                id="alto"
                                min="1"
                                max="99"
                                v-model="form.alto"
                                :class="
                                  $v.form.alto.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                              <div class="error" v-if="!$v.form.alto.required">
                                Diligencie un alto
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="peso_vacio"
                                >Peso Vacío <sub>(kilogramos)</sub></label
                              >
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="number"
                                class="form-control form-control-sm"
                                id="peso_vacio"
                                min="1"
                                max="100000"
                                v-model="form.peso_vacio"
                                :class="
                                  $v.form.peso_vacio.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                              <div
                                class="error"
                                v-if="!$v.form.peso_vacio.required"
                              >
                                Diligencie un peso vacío
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="capacidad_galones"
                                >Capacidad Galones</label
                              >
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="number"
                                class="form-control form-control-sm"
                                id="capacidad_galones"
                                min="1"
                                max="1000000"
                                v-model="form.capacidad_galones"
                              />
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="capacidad_toneladas"
                                >Capacidad toneladas</label
                              >
                              <input
                                :disabled="opcion == 1 || opcion == 3"
                                type="number"
                                class="form-control form-control-sm"
                                id="capacidad_toneladas"
                                min="1"
                                max="1000000"
                                v-model="form.capacidad_toneladas"
                              />
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="tipo_propiedad">Propiedad</label>
                              <select
                                :disabled="opcion == 1 || opcion == 3"
                                class="form-control form-control-sm"
                                id="tipo_propiedad"
                                v-model="form.tipo_propiedad"
                                :class="
                                  $v.form.tipo_propiedad.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              >
                                <option value="">Seleccione...</option>
                                <option
                                  v-for="propiedad in listasForms.propiedades"
                                  :key="propiedad.numeracion"
                                  :value="propiedad.numeracion"
                                >
                                  {{ propiedad.descripcion }}
                                </option>
                              </select>
                              <div
                                class="error"
                                v-if="!$v.form.tipo_propiedad.required"
                              >
                                Seleccione una propiedad
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-md-3">
                            <label for="tipo_tapa">Tipo de Tapa</label>
                            <select
                              :disabled="opcion == 1"
                              class="form-control form-control-sm"
                              id="tipo_tapa"
                              v-model="form.tipo_tapa"
                              :class="
                                $v.form.tipo_tapa.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="tapas in listasForms.tipo_tapas"
                                :key="tapas.numeracion"
                                :value="tapas.numeracion"
                              >
                                {{ tapas.descripcion }}
                              </option>
                            </select>
                            <div
                              class="error"
                              v-if="!$v.form.tipo_tapa.required"
                            >
                              Seleccione un tipo de tapa
                            </div>
                          </div>
                          <div class="form-group col-md-3">
                            <label for="tipo_tapa">Tipo de Remolque</label>
                            <select
                              :disabled="opcion == 1"
                              class="form-control form-control-sm"
                              id="tipo_tapa"
                              v-model="form.tipo_remolque"
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="tipos in listasForms.tipos_remolques"
                                :key="tipos.numeracion"
                                :value="tipos.numeracion"
                              >
                                {{ tipos.descripcion }}
                              </option>
                            </select>
                            <!-- <div
                              class="error"
                              v-if="!$v.form.tipo_remolque.required"
                            >
                              Seleccione un tipo de remolque
                            </div> -->
                          </div>
                        </div>
                        <!-- div lineas de negocio -->
                        <div
                          class="row"
                          v-if="
                            $route.params.accion == 'Editar' &&
                              $store.getters.can(
                                'admin.remolques.assingOrRevokeLinea'
                              )
                          "
                        >
                          <div class="col mt-12 mb-12 px-12">
                            <div class="col-md-12">
                              <a
                                class="btn btn-block btn-primary"
                                data-toggle="collapse"
                                href="#tipos"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                v-on:click="cargarLineas()"
                              >
                                Lineas de negocio
                              </a>
                              <div class="collapse show" id="tipos">
                                <div class="card card-body">
                                  <div class="row" style="margin-bottom: 10px">
                                    <div class="col-11">
                                      <select
                                        class="form-control form-control-sm"
                                        id="linea_negocio"
                                        v-model="lineaNegocio_id"
                                      >
                                        <option value="">Seleccione...</option>
                                        <option
                                          v-for="linea_negocio in listasForms.lineas_negocio"
                                          :key="linea_negocio.id"
                                          :value="linea_negocio.id"
                                        >
                                          {{ linea_negocio.nombre }}
                                        </option>
                                      </select>
                                    </div>
                                    <div class="col-1">
                                      <button
                                        class="btn btn-primary"
                                        v-if="lineaNegocio_id != ''"
                                        v-on:click="assingOrRevokeLinea(1)"
                                      >
                                        <i class="fas fa-save"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <!-- @change="findFilterBar($event)" -->
                                      <table
                                        class="
                                          table
                                          table-bordered
                                          table-striped
                                          table-hover
                                          table-sm
                                        "
                                      >
                                        <thead class="thead-dark">
                                          <tr>
                                            <th>Nombre</th>
                                            <th>Opciones</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="lineas in arregloLineas"
                                            :key="lineas.id"
                                          >
                                            <td>{{ lineas.nombre }}</td>
                                            <td>
                                              <button
                                                :disabled="
                                                  opcion == 1 || opcion == 3
                                                "
                                                type="button"
                                                class="btn btn-sm btn-danger"
                                                v-on:click="
                                                  assingOrRevokeLinea(
                                                    2,
                                                    lineas.id
                                                  )
                                                "
                                              >
                                                <i class="fas fa-trash-alt"></i>
                                              </button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3" v-if="form.id">
                        <label for="imagen">Foto Remolque</label>
                        <div
                          class="form-group"
                          v-if="form.link_fotografia == null"
                        >
                          <input
                            :disabled="opcion == 1"
                            type="file"
                            class="form-control-file"
                            accept="image/x-png,image/gif,image/jpeg"
                            @change="obtenerImagen"
                          />
                        </div>
                        <figure class="form-group" v-if="miniLogo">
                          <div class="button-container">
                            <img
                              :src="miniLogo"
                              alt="Logo"
                              width="236"
                              height="125"
                              class="rounded mx-auto d-block"
                            />
                            <a
                              :disabled="opcion == 1"
                              @click="destroyImage()"
                              id="BotonEliminar"
                              v-if="form.link_fotografia"
                              href="#"
                              >Eliminar <i class="fas fa-window-close"></i
                            ></a>
                          </div>
                        </figure>
                        <div class="row">
                          <div class="col-md-9"></div>
                          <div class="col-md-3" v-if="archivoValido">
                            <button
                              :disabled="opcion == 1"
                              type="button"
                              @click="saveImage()"
                              class="btn btn-success"
                              data-toggle="tooltip"
                              data-html="true"
                              title="Guardar Imagen"
                            >
                              <i class="fa fa-upload"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="Documentos" v-if="form.id">
                  <RemolqueDocumento
                    v-if="form.id != null"
                    ref="RemolqueDocumento"
                  />
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    :disabled="opcion == 1"
                    v-if="
                      $store.getters.can('admin.remolques.create') ||
                        $store.getters.can('admin.remolques.edit') ||
                        $store.getters.can(
                          'admin.remolques.editarTransportadora'
                        )
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RemolqueDocumento from "./RemolqueDocumento";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";

export default {
  name: "RemolqueForm",
  components: {
    RemolqueDocumento,
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      form: {
        empresa_id: null,
      },
      empresa: [],
      opcion: null,
      uploadPercentage: 0,
      uploadPercentageFoto: 0,
      lineaNegocio_id: "",
      arregloLineas: {},
      miniLogo: null,
      archivoValido: false,
      fileFotografia: null,
      accion: "",
      metodo: "",
      error: "",
      listasForms: {
        lineas_negocio: [],
        tipos_carroceria: [],
        estados: [],
        empresas: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      id: this.$route.params.id,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    form: {
      placa: {
        required,
        maxLength: maxLength(6),
        minLength: minLength(6),
      },
      tipo_carroceria: {
        required,
      },      
      modelo: {
        required,
        maxLength: maxLength(4),
        minLength: minLength(4),
      },
      numero_ejes: {
        maxLength: maxLength(2),
        required,
      },
      ancho: {
        required,
      },
      largo: {
        required,
      },
      alto: {
        required,
      },
      peso_vacio: {
        required,
      },
      empresa_id: {
        required,
      },
      link_fotografia: {},
      tipo_propiedad: {
        required,
      },
      tipo_tapa: {
        required,
      },
    },
    formSitios: {
      id: {
        required,
      },
    },
  },
  methods: {
    async getLineasNegocios() {
      await axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    async getTiposCarrocerias() {
      await axios.get("/api/lista/9").then((response) => {
        this.listasForms.tipos_carroceria = response.data;
      });
    },

    async getEstados() {
      await axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getTiposRemolques() {
      await axios.get("/api/lista/187").then((response) => {
        this.listasForms.tipos_remolques = response.data;
      });
    },

    selectEmpresa(search, loading) {
      let me = this;
      me.listasForms.empresas = [];
      if (search != "") {
        loading(true);
        var url = "api/admin/empresas/lista?razon_social=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;

            me.listasForms.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: `Sucedio un error inesperado ${error}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    obtenerImagen(e) {
      this.miniLogo = null;
      this.archivoValido = false;
      if (e.target.files[0]) {
        this.cargando = true;
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.archivoValido = true;
          this.fileFotografia = file;
          this.cargarImagen(file);
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        this.fileFotografia = null;
        this.cargando = false;
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.miniLogo = e.target.result;
      };
      reader.readAsDataURL(file);
      this.cargando = false;
    },

    saveImage() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("link_fotografia", this.fileFotografia);
      axios
        .post("api/admin/remolques/foto", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.archivoValido = false;
          this.form.link_fotografia = response.data.link_fotografia;
          this.cargando = false;
        })

        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    destroyImage() {
      this.$swal({
        title: "Esta seguro de eliminar esta Imagen?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/admin/remolques/foto/" + this.form.id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "La imagen se elimino correctamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch(function(error) {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
          this.miniLogo = null;
          this.form.link_fotografia = null;
        }
      });
    },

    save() {
      this.link_fotografia = this.form.link_fotografia;
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/remolques",
          data: this.form,
        })
          .then((response) => {
            this.$route.params.accion = "Editar";
            this.$route.params.data_edit = response.data;
            this.init();
            this.$swal({
              icon: "success",
              title: "El remolque se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            if (e.response.status == 500) {
              this.error = e.response.data.message;
              if (this.error.includes("1062 Duplicate entry")) {
                this.$swal({
                  icon: "error",
                  title: "La placa ya se encuentra creada en la base de datos",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "Ha ocurrido un error, intentelo nuevamente",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            } else {
              this.$swal({
                icon: "error",
                title:
                  "Ha ocurrido un error, por favor intente la acción nuevamente.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.cargando = false;
          });
      }
    },

    back() {
      return this.$router.replace("/Admin/Remolques");
    },

    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.opcion = this.$route.params.opcion;
        this.form = this.$route.params.data_edit;
        if (this.form.empresa) {
          this.empresa = {
            id: this.form.empresa.id,
            razon_social: this.form.empresa.razon_social,
          };
        }

        this.miniLogo = this.uri_docs + this.form.link_fotografia;
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
    },
    /* ---------------------------------------------
    |||||Funciones cargar lineas de negocio ||||||||
    ------------------------------------------------*/
    assingOrRevokeLinea(evento, linea_id) {
      var bandera = 0;

      if (evento == 1) {
        this.ItemInfo = {
          remolque_id: this.form.id,
          linea_negocio_id: this.lineaNegocio_id,
          evento: evento,
        };

        for (let index = 0; index < this.arregloLineas.length; index++) {
          if (this.arregloLineas[index].id == this.ItemInfo.linea_negocio_id) {
            bandera = 1;
          }
        }
      } else {
        this.ItemInfo = {
          remolque_id: this.form.id,
          linea_negocio_id: linea_id,
          evento: evento,
        };
      }

      if (bandera == 0) {
        axios({
          method: "PUT",
          url: "/api/admin/remolques/assingOrRevokeLinea",
          data: this.ItemInfo,
        })
          .then((response) => {
            this.$refs.RemolqueDocumento.getPaquetes();
            this.$refs.RemolqueDocumento.getRemolque(this.form.id);
            if (response.data == 1) {
              this.$swal({
                icon: "success",
                title: "Se Agrego la linea de manera correcta",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              this.lineaNegocio_id = "";
              this.cargarLineas();
            } else {
              this.$swal({
                icon: "info",
                title: "Se Elimino la linea de manera correcta",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              this.lineaNegocio_id = "";
              this.cargarLineas();
            }
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
      } else {
        this.$swal({
          icon: "error",
          title: "La linea ya se encuentra agregada",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
    async getPropiedades() {
      await axios.get("/api/lista/23").then((response) => {
        this.listasForms.propiedades = response.data;
      });
    },

    async getTipoTapas() {
      await axios.get("/api/lista/91").then((response) => {
        this.listasForms.tipo_tapas = response.data;
      });
    },
    cargarLineas() {
      axios
        .get("/api/admin/remolques/getLineas", {
          params: {
            Idremolque: this.form.id,
          },
        })
        .then((response) => {
          this.arregloLineas = response.data;
        });
    },
    checkEmpresa() {
      this.form.empresa_id = "";
      if (this.empresa) {
        this.form.empresa_id = this.empresa.id;
      }
    },

    validaRemolque() {
      let params = {
        placa: this.form.placa,
      };
      axios
        .get("/api/admin/remolques/lista", {
          params,
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.form.placa = null;
            this.$swal({
              icon: "error",
              title: "El remolque digitado ya se encuentra creado...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },
  },
  computed: {
    logo() {
      return this.miniLogo;
    },
  },
  async mounted() {
    this.cargando = true;
    await this.getLineasNegocios();
    await this.getTiposCarrocerias();
    await this.getEstados();
    await this.getPropiedades();
    await this.getTipoTapas();
    await this.getTiposRemolques();
    await this.init();
    this.cargando = false;
  },
};
</script>
<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
